import React from 'react'
import Helmet from 'react-helmet'

import './all.sass'

const TemplateWrapper = ({ children }) => (
  <div className="page-grid">
    <Helmet title="Ingenious Gaming" />
    < >{children}</ >
  </div>
)

export default TemplateWrapper
